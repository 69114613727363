import "./RazorPay.css";
import { useSelector } from "react-redux";
import { BookingDetailsService } from "../../../services";

export const useRazorpay = () => {
  const email = useSelector((state) => state?.userData?.user?.email);
  const name = useSelector((state) => state?.userData?.user?.name);
  const phone = useSelector((state) => state?.userData?.user?.phone);

  // console.error(email, phone);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (data, booking_id) => {
    const amountdata = data?.data?.amount / 100;

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      return alert("sdk failed to load,Are u offline??!!");
    }

    const order_id = data?.data?.id;
    const amount = data?.data?.amount;

    const currency = "INR";

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: amount.toString(),
      currency: currency,
      description: "White Lotus",
      order_id: order_id,
      handler: async function (response) {
        if (
          !(
            typeof response.razorpay_payment_id === "undefined" ||
            response.razorpay_payment_id < 1
          )
        ) {
          // window.location.href = `https://bookings.hotelwhitelotus.in/thankYou?booking_id=${booking_id?.[0]}`;
          window.location.href = `https://bookings.hotelgreenparksuites.com/thankYou?booking_id=${booking_id?.[0]}`;
          // BookingDetailsService.payment_success(response?.razorpay_order_id, booking_id, name, phone, amountdata)
          //   .then((response) => {
          //     if (response?.data?.status?.code === 0) {

          //       window.location.href = `http://bookings.hotelwhitelotus.in/thankYou?booking_id=${booking_id?.[0]}`;
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("Errorreg", error);
          //   });
        }
      },
      prefill: {
        email: email,
        mobile: phone,
        booking_id: booking_id,
      },
      notes: {
        name: name,
        mobile: phone,
        booking_id: JSON.stringify(booking_id),
        order_id: order_id,
        amount: amount,
      },
      readonly: { email: true },

      theme: {
        color: "black",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return {
    loadScript,
    displayRazorpay,
  };
};
