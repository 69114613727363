import { withTranslation } from "react-i18next";
import TopBar from "../../_common/topbar/TopBar";
import Footer from "../../_common/footer/Footer";
import "./cancelAndRefund.css";

function CancelAndRefund(props) {
  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  const termsAndCondition = () => {
    return (
      <div className="terms_a_cond_container flt">
        <div className="terms_header flt">Cancellation & Refund</div>

        <div className="sub_head flt">Booking Confirmation Policy</div>
        <div className="terms_content flt">
          <p>
            Confirmation of the booking is against an advance payment of minimum
            50% of the Reservations, balance to be paid on or before check in.
          </p>
        </div>
        <div className="sub_head flt">Refund</div>
        <div className="terms_content flt">
          <p>
            50% will be charged as retention charges if the Booking is cancelled
            within 15 days prior to the arrival date
          </p>
        </div>
        <div className="sub_head flt">No Refund</div>
        <div className="terms_content flt">
          <p>
            If the booking is confirmed with 100% payment and cancelled after 15
            days, the amount can be utilized for future date which will be valid
            for one year from the date of cancellation.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderTopBar()}
      {termsAndCondition()}
      {renderFooter()}
    </>
  );
}
export default withTranslation()(CancelAndRefund);
