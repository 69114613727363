import TopBar from "../../_common/topbar/TopBar";
import Vegas from "../../_common/Vegas/Vegas";

import {
  details,
  minical,
  room1_img,
  room_img,
  children_img,
  adult_new,
  adults,
  Loader,
} from "../../../assets/images";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import useStandard from "./useStandard";
import "./Standard.css";
import { useLocation } from "react-router";
import { commaValidation } from "../../../utility";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";
import Footer from "../../_common/footer/Footer";

const Standerd = (props) => {
  const { t } = props;

  const location = useLocation();
  var costData = 0;
  let RoomCount = 0;
  const {
    newArr,
    adult,
    extra,
    diffDate,
    children,
    service,
    rooms,
    roomData,
    totalGstCharge,
    totalPayableAmount,
    showShipping,
    pathimg,
    datetimevalue,
    datetimevalue1,
    loader,
    checkindate,
    totalRoomCharge,
    checkoutdate,
    extradata,
    taxdata,
    adultcount,
    datafinalvalue,
    datafinaltax,
    singleRoomInfo,
    roomAmount,
    handleChange,
    handleAdd,
    isInCategory,
    checkPrice,
    closeShipping,
    onChangeValue,
    openShipping,
    selectPath,
    continuToBook,
    onChangeValue1,
    handleChangeinc,
    handleChangedec,
    handleChangechildinc,
    handleChangechilddec,
    roomAdded,
    handleChangeRoominc,
    handleChangeRoomddec,
    handleDelete,
  } = useStandard(location, t);

  {
    RoomCount =
      Number(location?.state?.roomsavailable) - Number(adultcount.length);
  }
  /**
   *
   * @returns render top bar
   */

  const renderTopBar = () => {
    return <TopBar />;
  };
  /**
   * render vegas bar
   * @returns
   */
  const renderVegasBar = () => {
    return <Vegas />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  /**
   * render shipping details
   * @returns
   */
  const Shipping = () => {
    return (
      <div className="modalPop" id="shippingModal">
        <div className="modal-dialog modal-md modal-dailog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={closeShipping}>
                &times;
              </button>
            </div>

            <div className="modal-body">
              <Carousel showDots={false} autoPlay infinite={true}>
                {roomData &&
                  roomData?.[0] &&
                  roomData?.[0]?.room_image.map((list) => (
                    <div>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${list}`}
                        alt="no_image"
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /**
   * render room images
   * @returns
   */

  const renderRoomImages = () => {
    return (
      <>
        {roomData &&
          roomData.map((datas) => (
            <div className="main_view flt">
              <div className="right_view_room">
                <img
                  src={
                    pathimg
                      ? `${process.env.REACT_APP_MEDIA_DOMAIN}${pathimg}`
                      : datas?.room_image?.[0]
                      ? `${process.env.REACT_APP_MEDIA_DOMAIN}${datas?.room_image?.[0]}`
                      : room1_img
                  }
                  alt="no_image"
                />
              </div>
              <ul className="left_view_room ">
                {datas?.room_image
                  .filter((item, index) => index < 5)
                  .map((imgdata) => (
                    <li onClick={() => selectPath(imgdata)}>
                      <img
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${imgdata}`}
                        alt="no_image"
                      />
                      {/* {roomData?.[0]?.room_image?.length >= 4 ? "" :
                      <span onClick={openShipping}>
                        +{roomData?.[0]?.room_image?.length}
                      </span>} */}
                      {roomData?.[0]?.room_image?.length >= 4 ? (
                        <span onClick={openShipping}>
                          +
                          {Number(roomData?.[0]?.room_image?.length) -
                            Number(5)}
                        </span>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </>
    );
  };
  /**
   * render room description
   * @returns
   */
  const renderRoomDescription = () => {
    return (
      <div className="main_row_pad flt">
        <div className="row">
          <div className="col-sm-12">
            <ul className="standard_amenities flt">
              {roomData &&
                roomData?.[0]?.amenities.map((res) => (
                  <li>
                    <img
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${res?.amenities_image}`}
                      alt="no_image"
                    />
                    <span>{res?.amenities}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="main_description flt">
              <div className="room_description flt">
                {t("Room_Description")}
              </div>

              <div className="description">{roomData?.[0]?.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /**
   * render selecting room details
   * @returns
   */
  const selectedRoom = () => {
    return <div className="main_view_room flt"></div>;
  };

  /**
   * display checkin and checkout details
   * @returns
   */
  const checkinCheckoutDetails = () => {
    return (
      <div className="main_cal_pad flt">
        <div className="row">
          <div className="col-sm-6">
            <div className="che_pad flt">
              <div className="main_che_pad flt">
                <div className="main_cla ">
                  <div className="main_cal_image flt">
                    {/* <div className="main_imgcal"> </div> */}
                    <img src={minical} alt="no_image" />

                    <Datetime
                      name="checkin"
                      dateFormat="DD/MM/YYYY"
                      onChange={onChangeValue}
                      value={new Date(datetimevalue)}
                      closeOnSelect={true}
                      timeFormat={true}
                      isValidDate={(currentDate, selectedDate) =>
                        currentDate.isAfter(
                          new Date().getTime() - 24 * 60 * 60 * 1000
                        )
                      }
                      inputProps={{
                        placeholder: "Check-In",
                        disabled: true,
                        readOnly: true,
                        onKeyDown: (e) => e.preventDefault(),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="che_pad flt">
              <div className="main_che_pad flt">
                <div className="main_cla ">
                  <div className="main_cal_image flt">
                    <img src={minical} alt="no_image" />

                    <Datetime
                      dateFormat="DD/MM/YYYY"
                      name="checkout"
                      timeFormat={true}
                      onChange={onChangeValue1}
                      value={new Date(datetimevalue1)}
                      closeOnSelect={true}
                      isValidDate={(currentDate, selectedDate) =>
                        currentDate.isAfter(
                          new Date().getTime() - 24 * 60 * 60 * 1000
                        )
                      }
                      inputProps={{
                        placeholder: "Check-Out",
                        disabled: true,
                        readOnly: true,
                        onKeyDown: (e) => e.preventDefault(),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /**
   * gest room display
   * @returns
   */
  const guestRoom = () => {
    return (
      <div className="guest_pad flt">
        <div className="guest_flex flt">
          <div
            className={
              Number(location?.state?.roomsavailable) -
                Number(adultcount.length) ==
              0
                ? "addroom_btn opacityadd flt"
                : "addroom_btn flt"
            }
          >
            <button onClick={() => handleAdd()}>Add room</button>
          </div>

          <div className="room_rate flt">
            <span>Price:</span>{" "}
            {singleRoomInfo[0]?.price
              ? `₹ ${commaValidation(
                  Number(singleRoomInfo[0]?.price).toFixed(2)
                )}`
              : ""}
          </div>
        </div>
        <div className="price_flex flt">
          Total available rooms :{" "}
          {Number(location?.state?.roomsavailable) - Number(adultcount.length)}
        </div>
        <div className="main_guest_adults flt">
          {adultcount.map((data, i) => (
            <div className="guest_adults flt">
              <div className="main_adult_img">
                <img src={adult_new} alt="no_image" />
                <div className="person_count">
                  Room{i + 1}: {t("Adult")}
                </div>
              </div>

              <div className="main_increment">
                <select name="item" onChange={(e) => handleChange(e, i)}>
                  <option selected disabled>
                    Select adult count
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>

                <button className="room_remove" onClick={() => handleDelete(i)}>
                  X
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="row"></div>

        <div className="total_length flt">
          {t("Total_Length_of_stay")} :{" "}
          {commaValidation(
            diffDate && diffDate !== "0" ? Math.round(diffDate).toFixed(0) : "1"
          )}
        </div>

        <div className="total_length flt">Extra adult charge : ₹900 + tax</div>
        <div className="total_length flt">
          {" "}
          Extra Child ( 8 years and above) : ₹900 + tax
        </div>

        <div className="child_paxs">
          {" "}
          Complimentary breakfast served with veg options
        </div>

        <div className="table_container">
          <table className="table">
            <thead className="table-head">
              <tr className="table-row">
                <th className="table-header">Date</th>
                <th className="table-header">Room Price</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {singleRoomInfo?.slice(0, -1)?.map((ele, index) => (
                <tr className="table-row" data-index={index}>
                  <td className="table-data">
                    {new Date(ele?.date).toLocaleDateString("en-GB")}
                  </td>
                  <td className="table-data">{ele?.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  /**
   *
   * @returns this functionality is for adding extra service for a room
   */
  const exstraService = () => {
    return (
      <div className="main_services flt">
        {/* <div className="services">{t("Add_Extra_Services")}</div> */}
        <div className="main_check">
          {/* <ul>
            {service && service.map((data) =>
              <div className="check_box">
                <li>
                  <input
                    type="checkbox"
                    name="parking"
                    checked={isInCategory(data?.service_name)}
                    onChange={(e) => checkPrice(data?.service_name, data?.price, data?.people_choice, e, data?._id)}
                  />
                  <label>{data?.service_name}</label>
                </li>
                {(adult !== 0 || children !== 0) ?
                  <div className="parking_money">{data?.people_choice === true ? `₹ ${commaValidation((data?.price * (adult + children) * (diffDate === 0 ? 1 : diffDate)))}` : `₹ ${commaValidation(data?.price)}`}</div>
                  : <div className="parking_money">₹ {data?.people_choice === true ? commaValidation((data?.price) * (diffDate === 0 ? 1 : diffDate)) : commaValidation((data?.price))}</div>}
              </div>
            )}

          </ul> */}
        </div>

        <hr className="line" />

        <div className="main_room_charge flt">
          <ul className="room_charge ">
            <li>
              <div className="charges2">
                {t("Room_Charges")}({rooms}){" "}
              </div>
              <div className="charges2 charges_bold">
                ₹ {commaValidation(Number(totalRoomCharge).toFixed(2))}
              </div>
            </li>
            <li>
              <div className="charges2">{t("tax")} </div>
              <div className="charges2 charges_bold">
                ₹ {commaValidation(Number(totalGstCharge).toFixed(2))}
              </div>
            </li>
            {/* <li>
              <div className="charges2">{t("Additional_Charges")}</div>
              <div className="charges2 charges_bold">₹ {commaValidation(extra)}</div>
            </li> */}
          </ul>
          <hr className="line" />
        </div>

        <div className="main_total_amt flt">
          <ul className="total_amt flt">
            <li>
              <div className="ammount_clr">{t("Total_Payable_Amount")}</div>
              <div className="ammount_clr_no">
                {" "}
                ₹ {commaValidation(Number(totalPayableAmount).toFixed(2))}
              </div>
            </li>
            <hr className="line1" />
          </ul>
        </div>
        <div
          className={
            adultcount.length == 0
              ? "continue_btn opacityadd flt"
              : "continue_btn flt"
          }
        >
          <button className="main_continue flt" onClick={continuToBook}>
            {t("Continue_to_Book")}
          </button>
        </div>
      </div>
    );
  };
  /**
   * render checkin and checkout details
   * @returns
   */
  const renderCheckinCheckout = () => {
    return (
      <div className="col-sm-4">
        <div className="main_det flt">
          <div className="main_checkin flt">
            <div className="checkin flt">
              <img src={details} alt="no_image" />
              <div className="checkout">
                {t("Check-in &_Check-out_Details")}
              </div>
            </div>
            {loader !== "loading" && checkinCheckoutDetails()}
          </div>
        </div>
        <>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loader} alt="loader" />
            </div>
          )}
          {loader !== "loading" && (
            <div className="extra_ser flt">{exstraService()}</div>
          )}
        </>
      </div>
    );
  };
  /**
   * render room details
   * @returns
   */
  const renderRoomDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loader} alt="loader" />
          </div>
        )}
        {loader !== "loading" && (
          <>
            <div className="col-sm-8 main_Standerd_room">
              <div className="room_stnd flt"> {roomData?.[0]?.room_type} </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className=" flt">
                    {selectedRoom()}
                    <div className=" flt">
                      {renderRoomImages()}
                      {renderRoomDescription()}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="standard_middle flt">
                    <div className="main_discount flt">
                      <div className="main_room_rate flt">
                        <div className="addroom_block flt">{guestRoom()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-4">
             
            </div> */}
          </>
        )}
      </>
    );
  };

  return (
    <div className="main_container flt">
      {renderTopBar()}
      {renderVegasBar()}
      <div className="main_row  flt">
        <div className="row">
          {renderRoomDetails()}
          {renderCheckinCheckout()}
        </div>
      </div>
      {renderFooter()}

      {showShipping === true && Shipping()}
    </div>
  );
};
export default withTranslation()(Standerd);
