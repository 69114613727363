import "./footer.css";
import useFooter from "./useFooter"
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  insta,
  whatsapp,
  facebook,
  telephone,
  mobile,
  email,
  footerLogo,
} from "../../../assets/images";

function Footer(props) {
  const { t } = props;
  const {
  
    navigateToTermsAndCond,navigateToPrivacy,navigateToCancelRefund
   
  } = useFooter();
  const user_id = useSelector((state) => state?.userData?.user?._id);
  /**
   *
   * @returns render top bar
   */
  const renderFooter = () => {
    return (
      <div className="footer_container flt">
        <div className="footer_main flt">
          <div className="footer_box flt">
            <div className="footer_head flt">About Hotel</div>
            <div className="footer_logo flt">
              <img height="75px" width="75px" src={footerLogo} />
            </div>
            <div className="footer_content flt">
              Academy Towers,<br></br> Tiger Circle,<br></br> opp. Corporation
              Bank, <br></br> Manipal, Karnataka,<br></br> 576104
            </div>
          </div>
          <div className="footer_box flt">
            <div className="footer_head flt">Other Links</div>
            <ul className="footer_menulinks flt">
              <li onClick={navigateToTermsAndCond}>Terms & Conditions</li>
              <li onClick={navigateToPrivacy}>Privacy Policy</li>
              <li onClick={navigateToCancelRefund}>Cancellation & Refund Policy</li>
            </ul>
          </div>
          <div className="footer_box flt">
            <div className="footer_head flt">Contact</div>
            <ul className="footer_contact flt">
              <li>
                <img src={telephone} alt="telephone" />
                <a href="tel:0820 429 5701">0820 429 5701</a>
              </li>
              {/* <li>
                <img src={mobile} alt="mobile" />
                <a>0820 429 5701</a>
              </li> */}
              <li>
                <img src={email} alt="email" />
                <a href="mailto:hotelgreenparkmanipal@gmail.com">
                  hotelgreenparkmanipal@gmail.com
                </a>
              </li>
            </ul>
            <div className="footer_socialhead flt"> Follow Us</div>
            <ul className="footer_social flt">
              <li>
                <a href="https://hometowngalleria.in/" target="_blank">
                  <img src={insta} />
                </a>
              </li>
              <li>
                <a href="https://hometowngalleria.in/" target="_blank">
                  <img src={whatsapp} />
                </a>
              </li>
              <li>
                <a href="https://hometowngalleria.in/" target="_blank">
                  <img src={facebook} />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer_box flt">
            <div className="footer_head flt">Visit us</div>
            <div className="footer_map ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3881.9771977465302!2d74.78285157207506!3d13.351693958267873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbca4a97161d735%3A0x1c0cb4e29da821b!2sHotel%20Green%20Park%20Suites!5e0!3m2!1sen!2sin!4v1711521250140!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="footerbtm_border flt"></div>
        <div className="footer_btm flt">
          DESIGNED AND DEVELOPED BY{" "}
          <a href="https://www.chipsyservices.com/" target="_blank">
            CHIPSY IT SERVICES PVT. LTD.
          </a>
        </div>
      </div>
    );
  };

  return <div className="main_agile flt">{renderFooter()}</div>;
}
export default withTranslation()(Footer);
