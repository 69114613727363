import { withTranslation } from "react-i18next";
import TopBar from "../../_common/topbar/TopBar";
import Footer from "../../_common/footer/Footer";

import "./termsAndCondition.css";

function TermsAndCondition(props) {
 

  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  const termsAndCondition = () => {
    return (
      <div className="terms_a_cond_container flt">
        <div className="terms_header flt">Terms & Conditions</div>
        <div className="terms_content flt">
        <p> This website is operated by Hotel White Lotus. The terms “we”, “us”,
          and “our” refer to Hotel White Lotus. The use of our website is
          subject to the following terms and conditions of use, as amended from
          time to time (the “Terms”). The Terms are to be read together by you
          with any terms, conditions or disclaimers provided in the pages of our
          website. Please review the Terms carefully. The Terms apply to all
          users of our website, including without limitation, users who are
          browsers, customers, merchants, vendors and/or contributors of
          content. If you access and use this website, you accept and agree to
          be bound by and comply with the Terms of Use and our Privacy Policy.
          If you do not agree to the Terms of Use or our Privacy Policy, you are
          not authorized to access our website, use any of our website’s
          services or place an order / make a reservation on our website.</p>
         
        </div>
        <div className="sub_head flt">Website Use</div>
        <div className="terms_content flt">
          <p>
            You agree to use our website for legitimate purposes and not for any
            illegal or unauthorized purpose, including without limitation, in
            violation of any intellectual property or privacy law. By agreeing
            to the Terms, you represent and warrant that you are at least the
            age of majority in your country or state of residence and are
            legally capable of entering into a binding contract.
          </p>
          <p>
            You agree to not use our website to conduct any activity that would
            constitute a civil or criminal offence or violate any law. You agree
            not to attempt to interfere with our website’s network or security
            features or to gain unauthorized access to our systems. You agree to
            provide us with accurate personal information, such as your email
            address, mailing address and other contact details in order to
            complete your order or reservation you as needed. You agree to
            promptly update your account and information. You authorize us to
            collect and use this information to contact you in accordance with
            our Privacy Policy.
          </p>
        </div>
        <div className="sub_head flt">General Conditions</div>
        <div className="terms_content flt">
          <p>
            We reserve the right to refuse service to anyone, at any time, for
            any reason. We reserve the right to make any modifications to the
            website, including terminating, changing, suspending or
            discontinuing any aspect of the website at any time, without notice.
            We may impose additional rules or limits on the use of our website.
            You agree to review the Terms regularly and your continued access or
            use of our website will mean that you agree to any changes.
          </p>
          <p>
            You agree that we will not be liable to you or any third party for
            any modification, suspension or discontinuance of our website or for
            any service, content, feature, product or reservation offered
            through our website.
          </p>
        </div>
        <div className="sub_head flt">Products and Services</div>
        <div className="terms_content flt">
          <p>
            All purchases through our website are subject to product / room
            availability. We may, in our sole discretion, limit or cancel the
            quantities / inventory offered on our website or limit the sales of
            our products / reservations or services to any person, household,
            geographic region or jurisdiction.
          </p>

          <p>
            Prices for our products / reservations are subject to change,
            without notice. Unless otherwise indicated, prices displayed on our
            website are quoted in Indian Rupees. We reserve the right, in our
            sole discretion, to refuse orders / reservations, including without
            limitation, orders / reservations that appear to be placed by
            distributors or resellers. If we believe that you have made a false
            or fraudulent orders / reservations, we will be entitled to cancel
            the orders / reservations and inform the relevant authorities. We do
            not guarantee the accuracy of the Colour, Design, or Quality
            Standards of the rooms / products / services on our website. We have
            made efforts to ensure the Colour, Design, or Quality Standards of
            our rooms / products / services are maintained as accurately as
            possible.
          </p>
        </div>
        <div className="sub_head flt">Links to Third-Party Websites</div>
        <div className="terms_content flt">
          <p>
            Links from or to websites outside our website are meant for
            convenience only. We do not review, endorse, approve or control, and
            are not responsible for any sites linked from or to our website, the
            content of those sites, the third parties named therein, or their
            products and services. Linking to any other site is at your sole
            risk and we will not be responsible or liable for any damages in
            connection with linking. Links to downloadable software sites are
            for convenience only and we are not responsible or liable for any
            difficulties or consequences associated with downloading the
            software. Use of any downloaded software is governed by the terms of
            the license agreement, if any, which accompanies or is provided with
            the software.
          </p>
        </div>
        <div className="sub_head flt">
          User Comments, Feedback, and Other Submissions
        </div>
        <div className="terms_content flt">
          <p>
            You acknowledge that you are responsible for the information,
            profiles, opinions, messages, comments and any other content
            (collectively, the “Content”) that you post, distribute or share on
            or through our website or services available in connection with our
            website. You further acknowledge that you have full responsibility
            for the Content, including but limited to, with respect to its
            legality, and its trademark, copyright and other intellectual
            property ownership.
          </p>
          <p>
            You agree that any Content submitted by you in response to a request
            by us for a specific submission may be edited, adapted, modified,
            recreated, published, or distributed by us. You further agree that
            we are under no obligation to maintain any Content in confidence, to
            pay compensation for any Content or to respond to any Content.
          </p>
          <p>
            You agree that you will not post, distribute or share any Content on
            our website that is protected by copyright, trademark, patent or any
            other proprietary right without the express consent of the owner of
            such proprietary right. You further agree that your Content will not
            be unlawful, abusive or obscene nor will it contain any malware or
            computer virus that could affect our website’s operations. You will
            be solely liable for any Content that you make and its accuracy. We
            have no responsibility and assume no liability for any Content
            posted by you or any third-party.
          </p>
          <p>
            We reserve the right to terminate your ability to post on our
            website and to remove and/or delete any Content that we deem
            objectionable. You consent to such removal and/or deletion and waive
            any claim against us for the removal and/or deletion of your
            Content.
          </p>
        </div>
        <div className="sub_head flt">Your Personal Information</div>
        <div className="terms_content flt">
          <p>
            Please see our Privacy Policy to learn about how we collect, use,
            and share your personal information.
          </p>
        </div>
        <div className="sub_head flt">Errors and Omissions</div>
        <div className="terms_content flt">
          <p>
            Please note that our website may contain typographical errors or
            inaccuracies and may not be complete or current. We reserve the
            right to correct any errors, inaccuracies or omissions and to change
            or update information at any time, without prior notice (including
            after an order has been submitted). Such errors, inaccuracies or
            omissions may relate to product description, pricing, promotion and
            availability and we reserve the right to cancel or refuse any order
            / reservations placed based on incorrect pricing or availability
            information. We do not undertake to update, modify or clarify
            information on our website, except as required by law.
          </p>
        </div>
        <div className="sub_head flt">DISCLAIMER & LIMITATION OF LIABILITY</div>
        <div className="terms_content flt">
          <p>
            You assume all responsibility and risk with respect to your use of
            our website, which is provided “as is” without warranties,
            representations or conditions of any kind, either express or
            implied, with regard to information accessed from or via our
            website, including without limitation, all content and materials,
            and functions and services provided on our website, all of which are
            provided without warranty of any kind, including but not limited to
            warranties concerning the availability, accuracy, completeness or
            usefulness of content or information, uninterrupted access, and any
            warranties of title, non-infringement, merchantability or fitness
            for a particular purpose. We do not warrant that our website or its
            functioning or the content and material of the services made
            available thereby will be timely, secure, uninterrupted or
            error-free, that defects will be corrected, or that our websites or
            the servers that make our website available are free of viruses or
            other harmful components.
          </p>

          <p>
            The use of our website is at your sole risk and you assume full
            responsibility for any costs associated with your use of our
            website. We will not be liable for any damages of any kind related
            to the use of our website.
          </p>
          <p>
            n no event will we, or our affiliates, our or their respective
            content or service providers, or any of our or their respective
            directors, officers, agents, contractors, suppliers or employees be
            liable to you for any direct, indirect, special, incidental,
            consequential, exemplary or punitive damages, losses or causes of
            action, or lost revenue, lost profits, lost business or sales, or
            any other type of damage, whether based in contract or tort
            (including negligence), strict liability or otherwise, arising from
            your use of, or the inability to use, or the performance of, our
            website or the content or material or functionality through our
            website, even if we are advised of the possibility of such damages.
          </p>
          <p>
            Certain jurisdictions do not allow limitation of liability or the
            exclusion or limitation of certain damages. In such jurisdictions,
            some or all of the above disclaimers, exclusions, or limitations,
            may not apply to you and our liability will be limited to the
            maximum extent permitted by law.
          </p>
        </div>
        <div className="sub_head flt">Indemnification</div>
        <div className="terms_content flt">
          <p>
            You agree to defend and indemnify us, and hold us and our affiliates
            harmless, and our respective,officers, agents, contractors, and
            employees against any losses, liabilities, claims, expenses
            (including legal fees) in any way arising from, related to or in
            connection with your use of our website, your violation of the
            Terms, or the posting or transmission of any materials on or through
            the website by you, including but not limited to, any third party
            claim that any information or materials provided by you infringe
            upon any third party proprietary rights.
          </p>
        </div>
        <div className="sub_head flt">Entire Agreement</div>
        <div className="terms_content flt">
          <p>
            The Terms and any documents expressly referred to in them represent
            the entire agreement between you and us in relation to the subject
            matter of the Terms and supersede any prior agreement, understanding
            or arrangement between you and us, whether oral or in writing. Both
            you and we acknowledge that, in entering into these Terms, neither
            you nor we have relied on any representation, undertaking or promise
            given by the other or implied from anything said or written between
            you and us prior to such Terms, except as expressly stated in the
            Terms.
          </p>
        </div>
        <div className="sub_head flt">Waiver</div>
        <div className="terms_content flt">
          <p>
            Our failure to exercise or enforce any right or provision of the
            Terms will not constitute a waiver of such right or provision. A
            waiver by us of any default will not constitute a waiver of any
            subsequent default. No waiver by us is effective unless it is
            communicated to you in writing.
          </p>
        </div>
        <div className="sub_head flt">Severability</div>
        <div className="terms_content flt">
          <p>
            If any of the provisions of the Terms are determined by any
            competent authority to be invalid, unlawful or unenforceable, such
            provision will to that extent be severed from the remaining Terms,
            which will continue to be valid and enforceable to the fullest
            extent permitted by law.
          </p>
        </div>
        <div className="sub_head flt">Governing Law</div>
        <div className="terms_content flt">
          <p>
            Any disputes arising out of or relating to the Terms, the Privacy
            Policy, use of our website, or our products or services offered on
            our website will be resolved in accordance with the laws of INDIA.
          </p>
        </div>
        <div className="sub_head flt">Questions or Concerns</div>
        <div className="terms_content flt">
          <p>
            Please send all questions, comments and feedback to us at
            hotelgreenparkmanipal@gmail.com
          </p>
        </div>
        <div className="sub_head flt">Hotel Reservations</div>
        <div className="terms_content flt">
          <p>
            The reservations feature of the Website is provided solely to assist
            customers in determining the availability of travel related services
            and products and to make legitimate reservations and for no other
            purpose.
          </p>
          <p>
            You warrant that you are at least 18 years of age, possess the legal
            authority to enter into the legal agreement constituted by your
            acceptance of these Conditions and to use the Website in accordance
            with such Conditions.
          </p>
          <p>
            You agree to be financially responsible for your use of the Website
            including without limitation for all reservations made by you or on
            your account for you, whether authorized by you or not. For any
            reservations or other services for which fees may be charged, you
            agree to abide by the terms or conditions of supply including
            without limitation payment of all moneys due under such terms or
            conditions.
          </p>
          <p>
            The Website contains details of hotel charges and room rates
            (including any available special offers) for hotels owned by Green
            Park Suites.
          </p>
          <p>
            Hotel reservation terms and conditions of booking are set out on the
            Website and payment will be in accordance with the procedure set out
            in such terms and conditions. No contract will subsist between you
            and Hotel Home Town Galleria or any of its affiliates in respect of
            any services or products offered through the Website unless and
            until Hotel Home town Galleria accepts your order by e-mail or
            automated confirmation through the Website confirming that it has
            accepted your reservation, booking or order and any such contract
            shall be deemed to incorporate the hotel reservation terms and
            conditions of booking. Any other relevant terms and conditions
            relating to particular services or products are set out in the
            Website.
          </p>
          <p>
            You undertake that all details you provide to in connection with any
            services or products which may be offered by Hotel Home Town
            Galleria on the Website (including hotel room reservations) will be
            correct and, where applicable, the credit card which you use is your
            own and that there are sufficient funds to cover the cost of any
            services or products which you wish to purchase. Hotel Home Town
            Galleria reserves the right to obtain validation of your credit card
            details before providing you with any services or products. Product
            prices listed on the website are current. While every care has been
            taken to ensure that the prices are accurate, errors in data entry
            and updation may occur. We reserve the right to cancel the order /
            reservation in case a transaction has been made where the price
            indicated was not the correct price. In the rare event that happens,
            we will give a full refund of all money received from the customer.
          </p>
        </div>
        <div className="sub_head flt">Security & Non Confidentiality</div>
        <div className="terms_content flt">
          <p>
          Electronic transmissions of data including the Internet are public media and any use of such media is public not private.


          </p>
          <p>Information related to or arising from such use is either public or the property of those collecting the information and not personal or private information.</p>
          <p>Hotel Home Town Galleria does not warrant and cannot ensure the security or confidentiality of any information which you transmit to Hotel Home Town Galleria. Accordingly, any information which you transmit to Hotel Home Town Galleria is transmitted at your own risk.</p>
        </div>
        <div className="sub_head flt">Termination of Access</div>
        <div className="terms_content flt">
          <p>
          If you breach any of these Conditions your license to use the Website will terminate immediately without the necessity of any notice being given to you.
          </p>
          <p>Otherwise access to the Website may be terminated at any time by Hotel Home Town Galleria without notice.</p>
          <p>The provisions of these Conditions excluding, limiting and disclaiming the liability of Hotel Home Town Galleria will nevertheless survive any such termination.</p>
        </div>
        <div className="sub_head flt">General</div>
        <div className="terms_content flt">
          <p>
          Hotel Home Town Galleria does not accept any liability for any failure to comply with these Conditions where such failure is due to circumstances beyond its reasonable control.
          </p>
          <p>If Hotel Home Town Galleria waives any rights available to it under these Conditions on one occasion, this does not mean that those rights will automatically be waived on any other occasion.</p>
          <p>If any of these Conditions are invalid, unenforceable or illegal for any reason, the remaining Conditions shall nevertheless continue in full force.</p>
          <p>You are completely responsible for all charges, fees, duties, taxes and assessments arising out of the use of the Website.</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderTopBar()}
      {termsAndCondition()}
      {renderFooter()}
    </>
  );
}
export default withTranslation()(TermsAndCondition);
