import { useNavigate } from "react-router";

const useFooter = () => {
    const navigate = useNavigate();
     


     const navigateToTermsAndCond = () => {
          navigate("/terms-and-condition")
     }

     const navigateToPrivacy = () => {
        navigate("/privacy-policy")
   }

   const navigateToCancelRefund = () => {
    navigate("/cancellation-and-refund")
}

  

     return {
        navigateToTermsAndCond,navigateToPrivacy,navigateToCancelRefund
     }
}
export default useFooter
