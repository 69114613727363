import "./myaccount.css";
import TopBar from "../../_common/topbar/TopBar";
import Footer from "../../_common/footer/Footer";
import { Loader, profile1, sample } from "../../../assets/images";
import { bookingimg } from "../../../assets/images";
import { withTranslation } from "react-i18next";
import useMyAccount from "./useMyAccount";
import ReactPaginate from "react-paginate";

const Myaccount = (props) => {
  const { t } = props;

  const {
    mobile_no,
    fullName,
    email,
    submit,
    historyData,
    loader,
    loading,
    editing,
    pageCount,
    current,
    changedate,
    navigateToBook,
    handleChangeName,
    handleEditProfile,
    handleUpdateStatus,
    handleChangeEmail,
    changeCurrentPage,
  } = useMyAccount(t);
  //display the data that is given from api by mapping ,
  /**
   * if there is no records found then display booknow image and button
   */
  var disabledStatus;
  if (editing === false) {
    disabledStatus = true;
  } else {
    disabledStatus = false;
  }

  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  const profile = () => {
    return (
      <div className="my_profile flt">
        <div className="myprofile_main flt">
          <div className="row">
            <>
              {loader === "loading" && (
                <div className="main_load ">
                  <img src={Loader} alt="loader" />
                </div>
              )}
              {loader !== "loading" && (
                <>
                  <div className="col-sm-4">
                    <div className="profile_div flt">
                      <div className="profile_img flt">
                        <img src={profile1} alt="profileimage" />
                      </div>
                      <div className="profile_cont flt">{t("MY_PROFILE")}</div>
                      <div className="profile_form flt">
                        <form>
                          <div className="mob_numb flt">
                            <label>{t("Mobile_Number")}</label>
                            <div class="login_ip flt">
                              <input
                                type="text"
                                id="mobile_num"
                                value={mobile_no}
                                disabled
                              />
                              <span>+91</span>
                            </div>
                          </div>
                          <div className="full_name flt">
                            <label>{t("Full_Name")}</label>
                            <input
                              type="text"
                              id="name"
                              value={fullName}
                              onChange={handleChangeName}
                              placeholder={t("Enter_fullname")}
                              disabled={disabledStatus}
                            />

                            {submit && !fullName && (
                              <div className="invalid-feedback">
                                {t("Name_is_required")}
                              </div>
                            )}
                          </div>
                          <div className="email_addres flt">
                            <label>{t("Email_Addres")}</label>

                            <input
                              type="email"
                              id="email"
                              value={email}
                              onChange={handleChangeEmail}
                              placeholder={t("Enter_email")}
                              disabled={disabledStatus}
                            />

                            {submit && !email && (
                              <div className="invalid-feedback">
                                {t("Email_is_required")}
                              </div>
                            )}
                          </div>
                        </form>
                        <div className="profile_button flt">
                          {editing === false ? (
                            <button type="button" onClick={handleUpdateStatus}>
                              {t("EDIT_PROFILE")}
                            </button>
                          ) : (
                            <button type="button" onClick={handleEditProfile}>
                              {t("UPDATE_CHANGES")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
            <div className="col-sm-8">
              <>
                {loading === "loading" && (
                  <div className="main_load ">
                    <img src={Loader} alt="loader" />
                  </div>
                )}
                {loading !== "loading" && (
                  <>
                    {historyData && historyData?.data?.docs?.length == 0 ? (
                      <div className="right_profile flt">
                        <div className="pre_booking flt">
                          {t("PREVIOUS_BOOKINGS")}
                        </div>
                        <div className="booking_backgrnd flt">
                          <div className="booking_ing flt">
                            <img src={bookingimg} alt="bookimage" />
                          </div>
                          <div className="booking_histry flt">
                            {t("Booking_History")}
                          </div>
                          <div className="booking_button flt">
                            <button type="button" onClick={navigateToBook}>
                              {t("Book_Now")}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="stand_room flt">
                        <div className="myroom_top11 flt">
                          <div className="myroomdetails">
                            {t("MY_BOOKINGS")}
                          </div>
                          <div className="mr_viewall">
                            <button className="mrvbtn" onClick={navigateToBook}>
                              {t("View_All")}
                            </button>
                          </div>
                        </div>

                        {historyData?.data?.docs?.map((list) => (
                          <div
                            className={
                              list?.booking_status === 0
                                ? "seperate_room flt"
                                : "seperate_roomdark flt"
                            }
                          >
                            <div
                              className={
                                list?.booking_status === 0
                                  ? "roomtitle flt"
                                  : "roomtitledark flt"
                              }
                            >
                              {list?.Types?.[0]?.room_type}
                            </div>
                            <div className="row">
                              <div className="col-sm-2">
                                <div className="mybook_img flt">
                                  <img
                                    src={
                                      list?.Types?.[0]?.room_image?.[0]
                                        ?.filename
                                        ? `${process.env.REACT_APP_MEDIA_DOMAIN}/${list?.Types?.[0]?.room_image?.[0]?.filename}`
                                        : sample
                                    }
                                    alt="no_image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-5">
                                <ul
                                  className={
                                    list?.booking_status === 0
                                      ? "mybook_list flt"
                                      : "mybook_listdark flt"
                                  }
                                >
                                  <li></li>
                                  <li></li>
                                  <li>{t("Total_Guest")}</li>
                                  <li>
                                    {list?.adults ? list?.adults : "0"}{" "}
                                    {list?.adults === 0 || list?.adults === 1
                                      ? t("Adult")
                                      : t("Adults")}{" "}
                                    {/* {list?.children ? list?.children : "0"}{" "}
                                    {list?.children === 0 ||
                                      list?.children === 1
                                      ? t("Child")
                                      : t("Childrens")}{" "} */}
                                  </li>
                                  <li>{t("Total_Rooms")}</li>
                                  <li>
                                    {list?.no_of_room}{" "}
                                    {list?.no_of_room === 1
                                      ? t("Room")
                                      : t("Rooms")}
                                  </li>
                                </ul>
                              </div>
                              <div className="col-sm-5">
                                <ul
                                  className={
                                    list?.booking_status === 0
                                      ? "booking_confirmed flt"
                                      : "booking_confirmeddark flt"
                                  }
                                >
                                  <li>
                                    {list?.arrival_date} -{" "}
                                    {list?.departure_date}
                                  </li>
                                  <li>
                                    {t("Total_Length_of_stay")}{" "}
                                    {changedate(
                                      list?.arrival_date,
                                      list?.departure_date
                                    ) > 0
                                      ? changedate(
                                          list?.arrival_date,
                                          list?.departure_date
                                        )
                                      : "1"}
                                  </li>
                                  {list?.booking_status === 0 ? (
                                    <li>
                                      <i class="fas fa-check-circle"></i>{" "}
                                      {t("Booking_Confirmed")}
                                    </li>
                                  ) : list?.booking_status === 13 ? (
                                    <li>
                                      <i class="fas fa-check-circle"></i>{" "}
                                      {t("Booking_Cancelled")}
                                    </li>
                                  ) : (
                                    <li>
                                      <i class="fas fa-check-circle"></i>{" "}
                                      {t("Booking_Completed")}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
              <div className="paginationdiv flt">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  // currentPage={current}
                  showFirstLastPages={true}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={changeCurrentPage}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  forcePage={current - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main_container">
      {renderTopBar()}
      {profile()}
      {renderFooter()}
    </div>
  );
};

export default withTranslation()(Myaccount);
