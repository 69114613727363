import { withTranslation } from "react-i18next";
import TopBar from "../../_common/topbar/TopBar";
import Footer from "../../_common/footer/Footer";
import "./privacyPolicy.css";

function PrivacyPolicy(props) {
  const renderTopBar = () => {
    return <TopBar />;
  };

  const renderFooter = () => {
    return <Footer />;
  };

  const termsAndCondition = () => {
    return (
      <div className="terms_a_cond_container flt">
        <div className="terms_header flt">Privacy Policy</div>
        <div className="terms_content flt">
          <p>
            {" "}
            Maintaining the privacy of your information is important to Hotel
            Home Town Galleria. This Privacy Policy describes Hotel Home Town
            Galleria current policy on how we collect and use the “Personal
            Information” that you provide through
            bookings.hotelgreenparksuites.com (the “Website”). Kindly read the
            entire policy carefully. By using the site and you providing us with
            information, you consent to the Privacy Policy.
          </p>
          <p>
            Hotel Green Park Suites may revise this Privacy Policy from time to
            time to reflect changes in the law, Hotel Green Park Suites privacy
            practices, or the features on the Site. Any changes to our use of
            personal information will be reflected in such revised privacy
            policy as posted on this Site along with new effective date.
            Therefore, kindly review the Privacy Policy periodically to remain
            aware of Hotel Green Park Suites current policies and practices.
          </p>
          <p>
            Please note that this Privacy Policy is not a contract and does not
            create any legal rights or obligations.
          </p>
        </div>
        <div className="sub_head flt">Information that we collect</div>
        <div className="terms_content flt">
          <p>
            Personal Information collected through
            bookings.hotelgreenparksuites.com (the “Website”) about you may
            include, but is not limited to: Your name, address, telephone
            numbers and fax numbers, Email address. We also collect non Personal
            Information as described below.
          </p>
        </div>
        <div className="sub_head flt">Online Purchases/ Reservations</div>
        <div className="terms_content flt">
          <p>
            If you purchase Room nights/make reservation through
            bookings.hotelgreenparksuites.com (the “Website”) you provide
            Personal Information such as name, address, email address and
            telephone number to us in order to complete the transaction.
          </p>
        </div>
        <div className="sub_head flt">Automatic Collection</div>
        <div className="terms_content flt">
          <p>
            When you use the Site our servers may automatically collect
            non-personal information as you browse our Site. This non- personal
            Information may include your internet protocol (IP) address, IP
            address of your referral site, country, language, browser type,
            domain name, access time and duration of your session.
          </p>
        </div>
        <div className="sub_head flt">Cookies</div>
        <div className="terms_content flt">
          <p>
            Hotel Green Park Suites also collects information by using
            technologies and methodologies that enable tracking, such as
            “cookies.” Cookies are small text files that are placed on your
            computer when you visit our Site and are stored on your computer by
            your web browser. These technologies allow us to recognize your
            computer so that we can display advertising that may be of interest
            to you. Cookies can only be read by the domain that placed them on
            your computer. As described in more detail below, you can change
            your browser settings to refuse to accept cookies. However, if you
            change your browser settings to refuse to accept cookies, we cannot
            tailor the Site to meet your needs to the same degree.
          </p>
          <p>
            Hotel Green Park Suites may enter into agreements with third party
            companies to serve advertisements and information to Site users.
            Hotel Green Park Suites may use its cookies when working with these
            companies to provide them information regarding use of the Site or
            to serve you advertisements when you visit our Site. These companies
            may use the non-personal Information about your visit to make
            available goods and services of interest to you. You may change the
            settings on your Internet browser to reject cookies at any time, but
            it will make your use of the Site less convenient.
          </p>
        </div>
        <div className="sub_head flt">
          How we use the Personal Information/ Non Personal Information you
          provide
        </div>
        <div className="terms_content flt">
          <p>
            Hotel Green Park Suites uses Personal Information collected in
            conjunction with registration or purchases on our Site to complete
            transactions, to facilitate payment and to otherwise fulfill
            requests for services. We also use your Personal Information to
            provide you with a more effective customer experience. In order to
            do this, Hotel Green Park Suites may process your Personal
            Information or combine it with other information that we collect or
            that is publicly available.
          </p>
        </div>
        <div className="sub_head flt">Emails</div>
        <div className="terms_content flt">
          <p>
            Hotel Green Park Suites uses email addresses to deliver information
            relevant to our customers. The following are some examples of
            information: Confirmation Vouchers, route maps, special promotional
            offers, important travel related information and updates,
            reservation confirmations and surveys. We may also contact you via
            email for a number of other administrative and marketing purposes.
            As an enrollee in a contest or promotion, survey participant, or
            purchaser of room nights on our Site, you occasionally will receive
            emails about, without limitation, special offers, new products and
            other news items. If you do not wish to receive any promotional
            email sent to you by Hotel Green Park Suites kindly send an email to
            hotelgreenparkmanipal@gmail.com
          </p>
        </div>
        <div className="sub_head flt">Surveys</div>
        <div className="terms_content flt">
          <p>
            Hotel Green Park Suites may use Personal Information as well as the
            answers you provide to survey questions in combination with
            information collected from cookies and through other means specified
            in this policy to improve our services to you. We may contract with
            third parties to perform surveys. We may use Personal Information
            that you provide in filling out any surveys to send you information
            about, without limitation, contests or promotions, special offers,
            and other information we feel may be of interest to you.
          </p>
          <p>
            Hotel Green Park Suites uses Personal Information you provide us in
            connection with any contest or promotion to notify you if you are a
            winner and to send you information about, without limitation, future
            contests and promotions, special packages, and other information,
            which, we feel, may be of interest to you. We may also use Personal
            Information derived from contests or promotions in any of the other
            ways described by this Privacy Policy.
          </p>
        </div>
        <div className="sub_head flt">
          Domain Names and Internet Protocol Addresses
        </div>
        <div className="terms_content flt">
          <p>
            We aggregate Non Personal Information such as domain names and IP
            addresses to measure the number of visits, average time spent on the
            Site, pages viewed and similar Site usage information in order to
            improve the Site’s content and to better meet our customers’ needs
            and preferences.
          </p>
        </div>
        <div className="sub_head flt">
          Law Enforcement/Legal Processes/Other Uses
        </div>
        <div className="terms_content flt">
          <p>
            Hotel Green Park Suites may disclose Personal Information to comply
            with the law or a court order, subpoena or other legal process, as
            requested by a governmental or law enforcement authority. We may
            also disclose Personal Information when we in good faith believe
            that it is either necessary to protect our property or is otherwise
            necessary. Hotel Green Park Suites may also disclose Personal
            Information to any of its sister concerns, or to any entity with
            which it merges or to which it sells any assets.
          </p>
        </div>
        <div className="sub_head flt">Minors online privacy policy</div>
        <div className="terms_content flt">
          <p>
            We do not knowingly collect Personal Information from minors
            (according to applicable laws). If such minor has provided us with
            Personal Information without parental or guardian consent, the
            parent or guardian may email us hotelgreenparkmanipal@gmail.com, and
            we will remove the concerned minor Personal Information from any
            promotional lists or databases.
          </p>
        </div>
        <div className="sub_head flt">
          Privacy Policy relate to other websites
        </div>
        <div className="terms_content flt">
          <p>
            This Privacy Policy covers only “ bookings.hotelgreenparksuites.com
            ” (The website) and does not cover any other Site. While we make
            every effort to protect the Personal Information you provide to us,
            we do not control the policies of other sites (including those we
            may link to) and the site owners’ use of Personal Information you
            provide to them.
          </p>
        </div>
        <div className="sub_head flt">
          Prevention of unauthorized access of your Personal Information
        </div>
        <div className="terms_content flt">
          <p>
            To prevent unauthorized access, disclosure and improper use of your
            Personal Information, and to maintain data security, we have
            established appropriate physical, electronic, and managerial
            safeguards to protect the Personal Information we collect. Although
            Hotel Green Park Suites takes reasonable steps to safeguard and to
            prevent unauthorized access to your Personal Information, it cannot
            be responsible for the acts of those who gain unauthorized access
            and Hotel Green Park Suites make no warranty that we will prevent
            unauthorized access to your Personal Information.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderTopBar()}
      {termsAndCondition()}
      {renderFooter()}
    </>
  );
}
export default withTranslation()(PrivacyPolicy);
